body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #4725F4 !important;
}

#login {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;

  /* width: 400px; */
  height: 530px !important;
  margin-bottom: 128px !important;
}

picture {
  margin-bottom: 64px;
  margin-top: 64px;
}

@media(max-width: 640px) {
  h1 {
    margin-bottom: 0px !important;
    font-size: 40px !important;
    height: 72px !important;
    line-height: 100% !important;
  }

  h2 {
    font-size: 20px !important;
    height: 20px !important;
    line-height: 100% !important;
  }

  button {
    width: 350px !important;
    /* font-size: 12px !important; */
  }

  #tetris {
    width: 80vw;
  }
}

@media(max-height: 900px) {
   #login {
    /* gap: 0px; */
  }

  picture {
    /* margin-bottom: 24px; */
    margin-top: 24px;
  }

  h1 {
    /* margin-bottom: 0px !important; */
    /* font-size: 72px !important;
    height: 72px !important;
    line-height: 100% !important; */
  }

  /* h2 {
    height: 20px !important;
    font-size: 20px !important;
  } */

  #tetris {
    /* width: 20vw; */
    z-index: 1
  }
}

#tetris {
  width: 354px;
}

h2, h1 {
  font-family: 'Dela Gothic One', cursive;
  color: white !important;
  text-transform: uppercase !important;
}

h1 {
  font-size: 80px;
line-height: 110%;
height: 88px;
margin-bottom: 64px;
}

h2 {
  height: 26px;
  font-size: 24px;
line-height: 110%;
letter-spacing: 0.08em;
}

a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  font-family: 'Dela Gothic One', cursive;
  color: #FFFFFF !important;
}

button {
  background: #404EED;
  border: 3px solid #1F2AAD;
  box-shadow: 0px 4px 0px #1F2AAD, 0px 6px 2px rgba(0, 0, 0, 0.44), inset 0px 6px 0px rgba(255, 255, 255, 0.4);
  border-radius: 76px;
  padding: 0px 33px;
  gap: 16px;

  width: 450px;
  height: 74px;

  font-size: 16px !important;
  line-height: 150% !important;
  /* or 30px */

  letter-spacing: 0.06em !important;
  text-transform: uppercase !important;
  

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

button:hover {
  box-shadow: 1px 1px 30px 4px #1F2AAD;
    transition-duration: all 150ms ease;
    cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

footer {
  /* margin: 0 auto; */
  position: absolute;
  width: 100vw;
  height: auto;
  left: 0px;
  bottom: 0px;

  /* background: radial-gradient(53.51% 72.28% at 50.62% 25.72%, rgba(45, 129, 255, 0.6) 0%, rgba(45, 129, 255, 0) 100%); */
  display: flex;
  align-items: center;
  justify-content: center;
}


